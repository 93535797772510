import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";

import Img from "gatsby-image";
import FeatureGrid, { Feature } from "../components/Features";
import SubscribeBox from "../components/SubscribeForm";
import { HTMLContent } from "../components/Content";
import { Helmet } from "react-helmet";

const ProvidersPage = ({ data }) => {
  const { markdownRemark: page } = data;

  return (
    <Layout
      headerTitle={page.frontmatter.heading}
      headerPhoto={
        <Img
          imgStyle={{
            objectFit: "contain",
            objectPosition: "100% 100%",
          }}
          fluid={page.frontmatter.headerPhoto.childImageSharp.fluid}
        />
      }
      headerClassName="bg-accent curve-br"
      className={page.frontmatter.accentClass}
    >
      <Helmet titleTemplate="%s">
        <title>{`${page.frontmatter.title}`}</title>
        <meta property="og:title" content={page.frontmatter.title} />
      </Helmet>

      <section className="container mt-8 pb-8 md:pb-12 lg:pb-16">
        <div className="columns">
          <div className="w-full lg:w-10/12">
            <h2 className="font-display font-bold text-3xl text-accent-m1 leading-tight">
              {page.frontmatter.subtitle}
            </h2>
          </div>
        </div>

        <div className="columns">
          <div className="w-full md:w-5/12 lg:w-4/12">
            <p className="text-xl leading-relaxed mb-3">
              {page.frontmatter.lead_paragraph}
            </p>

            <Link
              to={page.frontmatter.lead_cta_action}
              className="btn mt-3 btn-accent btn-avenir-semibold md:w-full"
            >
              {page.frontmatter.lead_cta}
            </Link>
          </div>
          <div className="w-0 hidden lg:block lg:w-1/12"></div>
          <div className="w-full md:w-7/12 lg:w-5/12 text-lg space-y-2">
            <HTMLContent
              className="prose md:prose-lg"
              content={page.fields.top_intro_content_html}
            />
          </div>
        </div>
      </section>

      <div className="bg-accent-p2">
        <section className="py-6 pb-10 md:py-8 md:pb-20 lg:py-16 lg:pb-30">
          <div className="container">
            <div className="columns items-center">
              <div className="w-full md:w-5/12 lg:w-4/12 space-y-3">
                <HTMLContent
                  className="prose prose-accent-p2"
                  content={page.fields.mid_left_column_content_html}
                />
              </div>
              <div className="w-0 hidden lg:block lg:w-1/12"></div>
              <div className="w-full md:w-7/12 lg:w-5/12">
                <HTMLContent
                  className="prose prose-accent-p2"
                  content={page.fields.mid_right_column_content_html}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="bg-navy sm:bg-accent-p2 sm:bg-curve-tr-d-sm-navy bg-stretch py-6 md:py-8 lg:py-16 addon-rollie-top-foam">
          <div className="container">
            <div className="columns items-center">
              <figure className="w-full md:w-6/12 text-center">
                <img
                  className="w-full sm:w-3/4 md:w-full mx-auto"
                  alt="Chart of prescriptions returned to stock"
                  src={page.frontmatter.chart_image.publicURL}
                />
                <figcaption>
                  <p className="text-accent-p2">
                    {page.frontmatter.chart_caption}
                  </p>
                </figcaption>
              </figure>

              <div className="w-0 hidden lg:block lg:w-1/12"></div>
              <div className="w-full md:w-6/12 lg:w-5/12">
                <p className="text-white text-lg lg:text-xl leading-relaxed">
                  {page.frontmatter.chart_description}
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="py-6 md:py-8 lg:py-16">
        <div className="container">
          <div className="columns items-center">
            <div className="w-full lg:w-10/12">
              <FeatureGrid>
                <Feature
                  headingClassName="text-accent-m1"
                  heading={page.frontmatter.feature_1_heading}
                  iconName={page.frontmatter.feature_1_icon_name}
                >
                  <HTMLContent
                    content={page.fields.feature_1_content_html}
                  />
                </Feature>
                <Feature
                  headingClassName="text-accent-m1"
                  heading={page.frontmatter.feature_2_heading}
                  iconName={page.frontmatter.feature_2_icon_name}
                >
                  <HTMLContent
                    content={page.fields.feature_2_content_html}
                  />
                </Feature>

                <Feature
                  headingClassName="text-accent-m1"
                  heading={page.frontmatter.feature_3_heading}
                  iconName={page.frontmatter.feature_3_icon_name}
                >
                  <HTMLContent
                    content={page.fields.feature_3_content_html}
                  />
                </Feature>
                
                <Feature
                  headingClassName="text-accent-m1"
                  heading={page.frontmatter.feature_4_heading}
                  iconName={page.frontmatter.feature_4_icon_name}
                >
                  <HTMLContent
                    content={page.fields.feature_4_content_html}
                  />
                </Feature>
              </FeatureGrid>
            </div>
          </div>
        </div>
      </section>

      <article className="container">
        <div className="columns">
          <div className="w-full lg:w-10/12">
            <HTMLContent
              className="prose lg:prose-lg max-w-none"
              content={page.html}
            />
          </div>
        </div>
      </article>

      <section className="py-6 md:py-8 lg:py-16">
        <div className="container">
          <div className="columns items-center">
            <div className="w-full md:w-6/12 lg:w-5/12">
              <SubscribeBox title="Newsletter" />
            </div>

            <div className="hidden md:block w-1/12">&nbsp;</div>

            <div className="w-full text-center sm:text-left md:w-5/12 lg:w-4/12">
              <h4 className="font-display font-bold leading-tight text-2xl lg:text-3xl">
                Want to learn more?
              </h4>
              <HTMLContent
                className="prose"
                content={page.fields.footer_cta_content_html}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ProvidersPage;

export const providerPageQuery = graphql`
  query ProvidersPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        top_intro_content_html
        footer_cta_content_html
        mid_left_column_content_html
        mid_right_column_content_html
        
        feature_1_content_html
        feature_2_content_html
        feature_3_content_html
        feature_4_content_html
      }

      frontmatter {
        title
        accentClass
        heading
        subtitle
        lead_paragraph
        lead_cta
        lead_cta_action
        chart_caption
        chart_description
        chart_image {
          publicURL
        }
        feature_1_icon_name
        feature_1_heading
        feature_2_icon_name
        feature_2_heading
        feature_3_icon_name
        feature_3_heading
        feature_4_icon_name
        feature_4_heading
        footer_cta_heading
        headerPhoto {
          childImageSharp {
            fluid(
              maxWidth: 256
              quality: 80
              traceSVG: { background: "transparent", color: "#339888" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;
